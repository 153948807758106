* {
  font-family: "nunito-sans" !important;
}

body {
  margin: 0 !important;
}

.ant-select-selector {
  height: 40px !important;
  align-items: center;
  border: 1px solid #ced4db !important;
  border-radius: 2px !important;
  padding: 4px 12px !important;
}

.ant-spin {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
  max-height: 100% !important;
}

.ant-spin .ant-spin-dot {
  top: auto !important;
  inset-inline-start: auto !important;
  margin: auto !important;
}

.ant-input:hover,
.ant-select-selector:hover {
  border-color: #2f80ed !important;
}

.ant-input:focus,
.ant-input-number:focus,
.ant-select-selector:focus {
  border-color: #2f80ed !important;
  box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);
}

.fade-in-out {
  height: 78px;
  width: 78px;
  animation: fadeInOut 3s ease-in-out infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
